// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/public/not-development.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".monitoring_data[data-v-93da26a4]{height:calc(100% - 30px);width:100%;display:flex;flex-flow:row nowrap;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat}.monitoring_data .basic-container[data-v-93da26a4]{flex:1;background:#f7f5fb}", ""]);
// Exports
module.exports = exports;
