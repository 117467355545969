<template>
    <div class="monitoring_data">
        <catalog-tree
            class="catalog"
            :menulist="menulist"
            :defaultProps="defaultProps"
            :expandedKey.sync="itemId"
            :currentKey.sync="itemId"
            :checkedKey.sync="itemId"
            @handleNodeClick="handleNodeClick"
        >
        </catalog-tree>
        <router-view
            ref="route"
            class="basic-container"
            :itemName="itemName"
            :itemId="itemId"
            :currentChild.sync="currentChild"
        ></router-view>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import mixin from "../mixin";
import catalogTree from "@comp/catalog_tree.vue";
export default {
    name: "monitoring_data",
    components: {
        catalogTree,
    },
    mixins: [mixin],
    data() {
        return {
            menulist: [],
            defaultProps: {
                children: "Children",
                label: "MC",
            },
            basicType: "",
            itemId: "",
            itemName: "",
            currentChild: {
                ItemID: null,
            },
        };
    },
    computed: {
        ...mapState({
            departmentId: (state) => state.login.departmentId,
        }),
        ...mapGetters(["system"]),
    },
    mounted() {
        this.getTreeData();
    },
    methods: {
        ...mapActions(["GetBasicLeftTree"]),
        // 获得监测数据左侧列表数据
        async getTreeData() {
            let result = await this.GetBasicLeftTree({
                lb: 1,
                departmentId: localStorage.userId,
            });
            // console.log("左侧树列表服务结果", result);
            // 林业系统 没有父级菜单
            if (this.system == "system2" || this.system == "system3") {
                let list = [];
                result.map((item) => {
                    let state = false;
                    item.Children.map((itemInner1) => {
                        if (itemInner1.Children.length) {
                            state = true;
                        }
                        list = [...list, ...itemInner1.Children];
                    });
                    if (!state) {
                        list = [...list, ...item.Children];
                    }
                });

                list.map((item) => {
                    switch (item.ItemID) {
                        case "1302": // "管理制度和应急预案记录"
                            item.MC = "消防制度";
                            break;
                        case "20101": // "保护管理机构"
                            item.MC = "机构管理";
                            break;
                        case "1501": // "保护展示与环境整治工程记录"
                            item.MC = "林业项目";
                            break;
                    }
                });
                this.menulist = list || [];
            } else {
                this.menulist = result || [];
            }
            this.itemName = this.menulist.length ? this.menulist[0].MC : "";
            this.itemId = this.menulist.length ? this.menulist[0].ItemID : "";
            let paramID = this.$route.params.ItemID;
            let infoPageID = this.$route.query.ItemID;
            let store = JSON.parse(localStorage.getItem("basicCurrent"));
            if (
                store &&
                store.ItemID &&
                (store.ItemID === paramID || store.ItemID === infoPageID)
            ) {
                this.currentChild = store.params;
            } else if (this.menulist && this.menulist.length > 0) {
                this.currentChild = this.getItemName();
                this.changeCurrent();
            }
        },
        getFirstChild(list) {
            if (list.Children && list.Children.length > 0) {
                return this.getFirstChild(list.Children[0]);
            } else if (
                (list.Children && list.Children.length === 0) ||
                !list.Children
            ) {
                return list;
            }
        },
        changeCurrent() {
            if (!this.currentChild) {
                this.currentChild = this.getFirstChild(this.menulist[0]);
            }
            const monitorCurrent = JSON.stringify({
                ItemID: String(this.currentChild.ItemID),
                params: this.currentChild,
            });
            localStorage.setItem("monitorCurrent", monitorCurrent);
            localStorage.setItem(
                "monitoringPower",
                this.currentChild.CZQX || "0"
            );

            this.itemName = this.currentChild.MC;
            this.ItemID = this.currentChild.ItemID;
            this.$router
                .push({
                    name: "monitoringTable",
                    params: this.currentChild,
                })
                .then(() => {
                    // this.$refs.route.changeModule(this.currentChild);
                });
        },
        handleNodeClick(data) {
            let fileType = ["drawing", "document", "picture", "image"]; // 图纸、文档、图片、影像
            this.basicType = fileType[Number(data.LXMC) - 1];
            data.basicType = this.basicType;
            this.itemId = data.ItemID;
            this.itemName = data.MC;
            this.currentChild = data;
            this.changeCurrent();
        },
    },
};
</script>
<style lang="scss" scoped>
.monitoring_data {
    height: calc(100% - 30px);
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    background: url("~@image/public/not-development.png") center center
        no-repeat;
    .basic-container {
        flex: 1;
        background: #f7f5fb;
    }
}
</style>
<style lang="scss">
.monitoring_data {
    .basic_data_edit {
        #basic-upload {
            padding: 0;
        }
    }
    .el-tree-node__label {
        word-wrap: break-word;
        word-break: normal;
        white-space: normal;
        line-height: 2;
    }
}
</style>
